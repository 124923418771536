import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Heading2 } from '@entur/typography';
import Autosuggest from '~/components/Autosuggest/Autosuggest';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "geocoder"
    }}>{`Geocoder`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Geocoding is the computational process of transforming a physical address description to a location on the Earth's surface (spatial representation in numerical coordinates). Reverse geocoding, on the other hand, converts geographic coordinates to a description of a location, usually the name of a place or an addressable location. Geocoding relies on a computer representation of address points, the street / road network, together with postal and administrative boundaries.`}</p>
    </blockquote>
    <p><em parentName="p">{`-Wikipedia`}</em></p>
    <p>{`The Geocoder API can be used to query addresses, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Point_of_interest"
      }}>{`Points Of Interest`}</a>{` (POI) and public transport stops. The primary purpose of the geocoder is to find the start- and end points for queries to the journey planner API through an autosuggest interface.`}</p>
    <p>{`The following query will return a list of destination- or arrival points containing POI's, addresses and stops that match the string "sons":`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://api.entur.io/geocoder/v1/autocomplete?text=sons&lang=en"
        }}>{`https://api.entur.io/geocoder/v1/autocomplete?text=sons&lang=en`}</a></li>
    </ul>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`Here is an example of an autosuggest component created using the `}<inlineCode parentName="p">{`/autocomplete`}</inlineCode>{` endpoint. Search for places, get some info back.`}</p>
    <Autosuggest mdxType="Autosuggest" />
    <Heading2 style={{
      marginTop: '1rem'
    }} mdxType="Heading2">Authentication</Heading2>
    <p>{`This API is open under NLOD licence, however, it is required that all consumers identify themselves by using the header ET-Client-Name. Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: "company-application"`}</p>
    <p>{`Header examples:`}</p>
    <ul>
      <li parentName="ul">{`"brakar-journeyplanner"`}</li>
      <li parentName="ul">{`"fosen_utvikling-departureboard"`}</li>
      <li parentName="ul">{`"nor_way_bussekspress-nwy-app"`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      